import styled from "styled-components";

export const Container = styled.div`
  color: white;
  background: #1d0f0b;
  padding: 10px 14px;
  width: 100%;
  max-width: 750px;
  border-radius: 7px;
  flex: 1;
`;