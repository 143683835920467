import styled from 'styled-components';

export default styled.div`
  background-image: linear-gradient(#c1e1ff, #ffffff);
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
`;
