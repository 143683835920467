import styled, { css } from 'styled-components';
import { formatEther } from 'ethers/lib/utils';
import GameSection from './components/GameSection';
import Header from './components/Header';
import { useGameState } from './hooks/useGameState';
import GameOverModal from './components/GameOverModal';
import EventLog from './components/EventLog';
import Cloud from './components/Cloud';
import Footer from './components/Footer';
import SkyBackground from './components/SkyBackground';
import HowItWorks from './components/HowItWorks';

function App() {
  const { state: gameState, refresh: refreshGameState } = useGameState();

  return (
    <div className="App">
      <SkyBackground />
      <Logo src="/img/logo.png" />
      <Cloud speed={2} size="medium" />
      <Cloud speed={3} size="large" topOffset={30} />
      <Cloud speed={1} size="small" topOffset={85} />
      <Cloud speed={2} size="medium" topOffset={120} leftOffset={115} />
      <MainSection>
        <Header />
        <GameSection
          gameState={gameState}
          refreshGameState={refreshGameState}
        />
        {gameState && (
          <p style={{ marginTop: '0px' }}>
            Minimum deposit: {formatEther(gameState.minDepositAmount)} ETH
          </p>
        )}
        {gameState && gameState.state === 'GAME_OVER' && (
          <GameOverModal
            lastDepositAddress={gameState.lastDepositAddress}
            refreshGameState={refreshGameState}
          />
        )}
        <Hills>
          <LeftHill />
          <CenterHill />
          <RightHill />
        </Hills>
      </MainSection>
      <DirtDivider />
      <DirtSection>
        <EventLog />
        <HowItWorks />
        <Footer />
      </DirtSection>
    </div>
  );
}

const Logo = styled.img`
  width: 400px;
  margin-top: 20px;
  margin-left: 20px;
`;

const MainSection = styled.div`
  height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DirtDivider = styled.div`
  height: 10px;
  width: 100%;
  background: #4f2419;
  position: absolute;
  bottom: -10px;
`;

const DirtSection = styled.div`
  background-image: url('/img/dirt_bg.svg');
  background-size: cover;
  min-height: 500px;
  padding: 50px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  #how-it-works-heading {
    margin-top: 75px;
  }
`;

const baseHill = css`
  background-color: #64af1b;
  border-radius: 100% 100% 0 0;
  z-index: 1;
`;

const Hills = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  bottom: 0;
  max-height: 250px;
  width: 100%;
`;

const LeftHill = styled.div`
  ${baseHill}
  margin-left: -75%;
  width: 50%;
  padding: 60%;
  position: absolute;

  @media screen and (max-width: 600px) {
    background-color: transparent;
  }
`;

const CenterHill = styled.div`
  ${baseHill}
  margin-left: -37%;
  width: 50%;
  padding: 60%;
  position: absolute;
  box-shadow: 0 0 5px 0 black;
`;

const RightHill = styled.div`
  ${baseHill}
  margin-right: -70%;
  width: 50%;
  padding: 60%;

  @media screen and (max-width: 600px) {
    background-color: transparent;
  }
`;

export default App;
