import { getEnvironment } from '../environment';

const Footer = () => {
  return (
    <div style={{ marginTop: '20px' }}>
      Made by{' '}
      <a
        href="https://twitter.com/mikeroelens"
        target="_blank"
        rel="noreferrer"
      >
        @mikeroelens
      </a>
      <br />
      <a
        href={`https://${getEnvironment().etherscanDomain}/address/${
          getEnvironment().contractAddress
        }`}
      >
        Etherscan
      </a>{' '}
      |{' '}
      <a
        href="https://goerli.piggybankbomb.com"
        target="_blank"
        rel="noreferrer"
      >
        Goerli
      </a>{' '}
      |{' '}
      <a
        href="https://github.com/mikeroelens/piggybankbomb"
        target="_blank"
        rel="noreferrer"
      >
        GitHub
      </a>{' '}
    </div>
  );
};

export default Footer;
