import Modal from 'react-modal';
import styled from 'styled-components';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { useState } from 'react';
import { PiggyBankBomb__factory } from '../types/gen';
import { getEnvironment } from 'src/environment';

Modal.setAppElement('#root');

type Props = {
  lastDepositAddress: string;
  refreshGameState: () => void;
};

// TODO: FIXME: Deduplicate tx logic from DepositModal

type ModalState =
  | 'GAME_OVER_MESSAGE'
  | 'CHECKING_PROVIDER'
  | 'METAMASK_MISSING'
  | 'PENDING_CONNECTION'
  | 'FAILED_TO_CONNECT'
  | 'INVALID_CHAIN_ID'
  | 'PENDING_SIGNING'
  | 'WAITING_FOR_TRANSACTION';

const GameOverModal = ({ lastDepositAddress, refreshGameState }: Props) => {
  const [modalState, setModalState] = useState<ModalState>('GAME_OVER_MESSAGE');
  const [txHash, setTxHash] = useState('');

  const startNewGame = async () => {
    const provider = (await detectEthereumProvider()) as any;
    if (!provider) {
      setModalState('METAMASK_MISSING');
      return;
    }
    setModalState('PENDING_CONNECTION');

    const web3Provider = new ethers.providers.Web3Provider(provider);
    try {
      // @ts-ignore
      await web3Provider.provider.request({
        method: 'eth_requestAccounts',
      });
    } catch (e) {
      console.error('failed to connect wallet', e);
      setModalState('FAILED_TO_CONNECT');
      return;
    }

    const signer = web3Provider.getSigner();
    const env = getEnvironment();

    // Ensure wallet chain id matches expected
    const signerChainId = await signer.getChainId();
    if (signerChainId !== env.chainId) {
      setModalState('INVALID_CHAIN_ID');
      return;
    }

    const contract = PiggyBankBomb__factory.connect(
      env.contractAddress,
      signer
    );
    setModalState('PENDING_SIGNING');

    try {
      const transaction = await contract.startNewGame();
      setTxHash(transaction.hash);
      setModalState('WAITING_FOR_TRANSACTION');
      await transaction.wait();
      refreshGameState();
    } catch (e) {
      console.error(e);
    }
  };

  const message = () => {
    switch (modalState) {
      case 'GAME_OVER_MESSAGE':
        return (
          <>
            <h2>Game over! 💥</h2>
            <p>{lastDepositAddress} wins all of the ETH!</p>
            <p>
              In order to withdraw the winnings a new game must be started.
              Anyone can start a new game by clicking "Start New Game" below.
            </p>

            <button onClick={startNewGame}>Start New Game</button>
          </>
        );
      case 'CHECKING_PROVIDER':
        return <p>Looking for wallet...</p>;
      case 'METAMASK_MISSING':
        return (
          <p>
            You must have MetaMask installed. <br />
            Install the MetaMask Chrome Extension{' '}
            <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en">
              here.
            </a>
          </p>
        );

      case 'FAILED_TO_CONNECT':
        return <p>⚠️ Failed to connect to wallet</p>;
      case 'INVALID_CHAIN_ID':
        return (
          <>
            <p>
              ⚠️ Failed to connect to wallet
              <br /> Make sure you are connected to the{' '}
              <b>{getEnvironment().networkName}</b> network!
            </p>
          </>
        );
      case 'PENDING_CONNECTION':
        return <p>Waiting for wallet connection...</p>;
      case 'PENDING_SIGNING':
        return <p>Waiting for wallet confirmation...</p>;
      case 'WAITING_FOR_TRANSACTION':
        return (
          <>
            <p>Waiting for transaction to be confirmed...</p>
            View transaction on{' '}
            <a
              href={`https://${getEnvironment().etherscanDomain}/tx/${txHash}`}
              target="_blank"
              rel="noreferrer"
            >
              Etherscan
            </a>
          </>
        );
    }
  };
  return (
    <Modal
      isOpen={true}
      style={{
        overlay: { zIndex: 100 },
        content: {
          top: '25%',
          left: '25%',
          right: '25%',
          bottom: 'auto',
          border: '4px solid black',
          width: '90vw',
        },
      }}
      contentLabel="GameOver"
    >
      <ModalContainer>
        <BrokenPiggy
          src="/img/piggy_game_over.jpg"
          alt="Piggy bank broken open with coins falling out"
        />
        {message()}
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BrokenPiggy = styled.img`
  width: 50%;
  max-width: 350px;
`;

export default GameOverModal;
