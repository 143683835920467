import { useCallback, useState, memo } from 'react';
import styled from 'styled-components';
import DepositModal from './DepositModal';

type Props = {
  refreshGameState: () => void;
};

const coinThickness = 20;
const coinDiameter = 115;
const coinTurnTime = 4;
const numEdgeFaces = 80;
const edgeFaceLength = (3.14 * coinDiameter) / numEdgeFaces;

const coinColorR = 248;
const coinColorG = 189;
const coinColorB = 51;

const DepositCoin = memo(({ refreshGameState }: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <Coin onClick={() => setModalOpen(true)}>
        <CoinFront />
        {[...Array(numEdgeFaces).keys()].map((index) => (
          <CoinEdge index={index + 1} />
        ))}
        <CoinBack />
      </Coin>
      <DepositModal
        isOpen={modalOpen}
        close={onClose}
        onSuccess={refreshGameState}
      />
    </>
  );
});

const Coin = styled.div`
  cursor: pointer;
  position: relative;
  width: ${coinDiameter}px;
  height: ${coinDiameter}px;
  transform-style: preserve-3d;
  animation: rotate3d ${coinTurnTime}s linear infinite;
  transition: all 0.3s;
  margin-left: 260px;
  margin-bottom: -30px;
  z-index: 2;

  @keyframes rotate3d {
    0% {
      transform: perspective(1000px) rotateY(0deg);
    }

    100% {
      transform: perspective(1000px) rotateY(360deg);
    }
  }
`;

const CoinSide = styled.div`
  position: absolute;
  width: ${coinDiameter}px;
  height: ${coinDiameter}px;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(${coinColorR}, ${coinColorG}, ${coinColorB});
  background-image: url('/img/coin.png');
  background-size: cover;

  @keyframes shine {
    0%,
    15% {
      transform: translateY(${coinDiameter * 2}px) rotate(-40deg);
    }
    50% {
      transform: translateY(-${coinDiameter}) rotate(-40deg);
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: -${coinDiameter / 2}px;
    bottom: 100%;
    display: block;
    height: ${coinDiameter / 1.5}px;
    width: ${coinDiameter * 2}px;
    background: #fff;
    opacity: 0.3;
    animation: shine linear ${coinTurnTime / 2}s infinite;
  }
`;

const CoinFront = styled(CoinSide)`
  transform: translateZ(${coinThickness / 2}px);
`;

const CoinBack = styled(CoinSide)`
  transform: translateZ(-${coinThickness / 2}px) rotateY(180deg);
`;

const coinEdgeColor = (index: number) => {
  const multiplier = 1 - index / numEdgeFaces / 2;

  return `rgb(${coinColorR * multiplier}, ${coinColorG * multiplier}, ${
    coinColorB * multiplier
  })`;
};

const CoinEdge = memo(styled.div<{ index: number }>`
  position: absolute;
  height: ${edgeFaceLength}px;
  width: ${coinThickness}px;
  background: ${(p) => coinEdgeColor(p.index)};
  transform: ${(p) => `
    translateY(${coinDiameter / 2 - edgeFaceLength / 2}px) 
    translateX(${coinDiameter / 2 - coinThickness / 2}px) 
    rotateZ(${(360 / numEdgeFaces) * p.index + 90}deg)
    translateX(${coinDiameter / 2}px) 
    rotateY(90deg);
    `};
`);

export default DepositCoin;
