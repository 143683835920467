import styled from 'styled-components';

const Header = () => {
  return (
    <>
      <Title>Last one to deposit wins it all!</Title>
      <Subtitle>...but every deposit resets the clock.</Subtitle>
    </>
  );
};

const Title = styled.h1`
  text-align: center;
  font-size: min(5vw, 55px);
  margin-bottom: 0px;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: min(3vw, 25px);
  margin-top: 5px;
`;
export default Header;
