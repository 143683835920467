import { Heading } from './Heading';
import { Container } from './Container';

const HowItWorks = () => {
  return (
    <>
      <Heading id="how-it-works-heading">How it Works</Heading>
      <Container style={{padding: '30px'}}>
        ⏱️ When the timer runs out the Piggy Bank explodes and...
        <ul>
          <li>
            95% of the ETH goes to the last person to deposit
          </li>
          <li>
            5% of the ETH remains in the Piggy Bank for the next game
          </li>
        </ul>

        <br />
        
        HOWEVER... when someone deposits ETH, the timer resets to 60 minutes.

        <br /><br />
        And don't worry, the minimum deposit grows over time to keep things interesting!
      </Container>
    </>
  );
};

export default HowItWorks;
