import { BigNumber } from '@ethersproject/bignumber';
import { useState, useEffect, useCallback } from 'react';
import { readOnlyContract } from '../contract';
import { GameState } from '../types';

type GameStateHook = {
  loading: boolean;
  state: GameState | null;
  refresh: () => void;
};

const determineState = (
  lastDepositTime: BigNumber,
  maxBombTime: number
): GameState['state'] => {
  const secondsLeft = Math.max(
    lastDepositTime.toNumber() + maxBombTime - Math.round(Date.now() / 1000),
    0
  );

  if (secondsLeft > 0) {
    return 'IN_PROGRESS';
  }

  return 'GAME_OVER';
};

// TODO: Handle errors
const refreshGameState = async (): Promise<GameState> => {
  console.log('REFRESHING GAME STATE FROM PROVIDER!');

  const contract = readOnlyContract();
  const [
    piggyBankBalance,
    lastDepositTime,
    lastDepositAddress,
    maxBombTime,
    minDepositAmount,
  ] = await Promise.all([
    contract.piggyBankBalance(),
    contract.lastDepositTime(),
    contract.lastDepositAddress(),
    contract.bombTimerMaxSeconds(),
    contract.calculateMinDeposit(),
  ]);

  return {
    piggyBankBalance,
    lastDepositAddress,
    lastDepositTimestamp: lastDepositTime.toNumber(),
    maxBombTime,
    minDepositAmount,
    state: determineState(lastDepositTime, maxBombTime),
  };
};

export const useGameState = (): GameStateHook => {
  const [gameState, setGameState] = useState<GameState | null>(null);
  const [loading, setLoading] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);

  const refreshCallback = useCallback(() => {
    setRefreshCount((prev) => prev + 1);
  }, []);

  useEffect(() => {
    let didCancel = false;

    const refresh = async () => {
      setLoading(true);
      const state = await refreshGameState();

      if (!didCancel) {
        setGameState(state);
        setLoading(false);
      }
    };

    refresh();

    return () => {
      didCancel = true;
    };
  }, [refreshCount]);

  return {
    state: gameState,
    loading,
    refresh: refreshCallback,
  };
};
