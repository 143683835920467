import styled, { keyframes } from 'styled-components';

const cloudAnimation = keyframes`
    0% {
      margin-left: 100vw;
    }
    100% {
      margin-left: -100vw;
    }
  `;

type Props = {
  speed: 1 | 2 | 3;
  size: 'small' | 'medium' | 'large';
  leftOffset?: number;
  topOffset?: number;
};

const speedToSeconds = (speed: Props['speed']) => {
  switch (speed) {
    case 1:
      return 60;
    case 2:
      return 45;
    case 3:
      return 30;
  }
};

const sizeToScale = (speed: Props['size']) => {
  switch (speed) {
    case 'small':
      return 0.3;
    case 'medium':
      return 0.4;
    case 'large':
      return 0.6;
  }
};

export default styled.img.attrs({ src: '/img/cloud.png' })`
  position: absolute;
  top: ${(p: Props) => p.topOffset || 0}px;
  left: ${(p: Props) => p.leftOffset || 0}px;
  animation-name: ${cloudAnimation};
  animation-duration: ${(p: Props) => speedToSeconds(p.speed)}s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: -1;
  -webkit-transform: scale(${(p: Props) => sizeToScale(p.size)});
  -moz-transform: scale(${(p: Props) => sizeToScale(p.size)});
  transform: scale(${(p: Props) => sizeToScale(p.size)});
`;
