import styled from 'styled-components';

const Button = styled.button`
  background: white;
  color: black;
  font-size: 1.25em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid black;
  border-radius: 3px;
  &:hover {
    background-color: #efefef;
    cursor: pointer;
  }
`;

export default Button;
