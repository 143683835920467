import Modal from 'react-modal';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { useEffect, useState } from 'react';
import { PiggyBankBomb__factory } from '../types/gen';
import { getEnvironment } from 'src/environment';
import Button from './Button';

Modal.setAppElement('#root');

type Props = {
  isOpen: boolean;
  close: () => void;
  onSuccess: () => void;
};

type DepositState =
  | 'CHECKING_PROVIDER'
  | 'METAMASK_MISSING'
  | 'PENDING_CONNECTION'
  | 'FAILED_TO_CONNECT'
  | 'INVALID_CHAIN_ID'
  | 'CALCULATING_MIN_DEPOSIT'
  | 'PENDING_SIGNING'
  | 'WAITING_FOR_TRANSACTION';
// TODO: Styling

const message = (depositState: DepositState, hash: string | undefined) => {
  switch (depositState) {
    case 'CHECKING_PROVIDER':
      return <p>Looking for wallet...</p>;
    case 'METAMASK_MISSING':
      return (
        <p>
          You must have MetaMask installed. <br />
          Install the MetaMask Chrome Extension{' '}
          <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en">
            here.
          </a>
        </p>
      );

    case 'FAILED_TO_CONNECT':
      return <p>⚠️ Failed to connect to wallet</p>;
    case 'INVALID_CHAIN_ID':
      return (
        <>
          <p>
            ⚠️ Failed to connect to wallet <br /> Make sure you are connected to
            the <b>{getEnvironment().networkName}</b> network!
          </p>
        </>
      );
    case 'PENDING_CONNECTION':
      return <p>Waiting for wallet connection...</p>;
    case 'CALCULATING_MIN_DEPOSIT':
      return <p>Calculating minimum deposit...</p>;
    case 'PENDING_SIGNING':
      return <p>Waiting for wallet confirmation...</p>;
    case 'WAITING_FOR_TRANSACTION':
      return (
        <>
          <p>Waiting for transaction to be confirmed...</p>
          View transaction on{' '}
          <a
            href={`https://${getEnvironment().etherscanDomain}/tx/${hash}`}
            target="_blank"
            rel="noreferrer"
          >
            Etherscan
          </a>
        </>
      );
  }
};

// TODO: Refresh min deposit
// Warn if someone deposited since?
const DepositModal = ({ isOpen, close, onSuccess }: Props) => {
  const [depositState, setDepositState] =
    useState<DepositState>('CHECKING_PROVIDER');
  const [txHash, setTxHash] = useState('');

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    console.log('DepositModal useEffect');
    let didCancel = false;

    const execute = async () => {
      const provider = (await detectEthereumProvider()) as any;
      if (!provider) {
        if (!didCancel) {
          setDepositState('METAMASK_MISSING');
        }
        return;
      }

      if (!didCancel) {
        setDepositState('PENDING_CONNECTION');
      }

      const web3Provider = new ethers.providers.Web3Provider(provider);
      try {
        // @ts-ignore
        await web3Provider.provider.request({
          method: 'eth_requestAccounts',
        });

        if (!didCancel) {
          setDepositState('CALCULATING_MIN_DEPOSIT');
        }

        console.log('connecting complete');
        // const accounts = await web3Provider.listAccounts()
        // console.log("accounts", accounts)
      } catch (e) {
        console.error('failed to connect wallet', e);
        if (!didCancel) {
          setDepositState('FAILED_TO_CONNECT');
        }
        return;
      }

      const signer = web3Provider.getSigner();
      const env = getEnvironment();

      // Ensure wallet chain id matches expected
      const signerChainId = await signer.getChainId();
      if (signerChainId !== env.chainId) {
        setDepositState('INVALID_CHAIN_ID');
        return;
      }

      const contract = PiggyBankBomb__factory.connect(
        env.contractAddress,
        signer
      );

      const minDepositAmount = await contract.calculateMinDeposit();
      if (!didCancel) {
        setDepositState('PENDING_SIGNING');
      }

      try {
        const transaction = await contract.deposit({
          value: minDepositAmount,
        });

        setTxHash(transaction.hash);

        setDepositState('WAITING_FOR_TRANSACTION');
        await transaction.wait();
        // TODO: Refresh game
        if (!didCancel) {
          onSuccess();
          close();
        }
      } catch (e) {
        console.error(e);
        close();
      }
    };

    execute();

    return () => {
      didCancel = true;
    };
  }, [isOpen, close, onSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      style={{
        overlay: { zIndex: 100 },
        content: {
          top: '25%',
          left: '25%',
          right: '25%',
          bottom: 'auto',
          border: '4px solid black',
          minHeight: '35vh',
        },
      }}
      contentLabel="Deposit"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <h2>Deposit</h2>
        {message(depositState, txHash)}
        <Button onClick={close}>Close</Button>
      </div>
    </Modal>
  );
};

export default DepositModal;
