import { formatTime } from '../../utils';
import styled from 'styled-components';
import PiggyHappy from './PiggyHappy';
import PiggySad from './PiggySad';

export const PiggyBankSvg = styled.svg`
  max-width: 700px;
  width: 70%;
`;

type Props = {
  secondsRemaining: number | null;
  isHappy: boolean;
};

const PiggyBank = ({ secondsRemaining, isHappy }: Props) => {
  const formattedTime =
    secondsRemaining !== null ? formatTime(secondsRemaining) : '88:88';

  const [minutes, seconds] = formattedTime.split(':');

  return (
    <PiggyBankContainer>
      {isHappy ? (
        <PiggyHappy minutes={minutes} seconds={seconds} />
      ) : (
        <PiggySad minutes={minutes} seconds={seconds} />
      )}
    </PiggyBankContainer>
  );
};

const PiggyBankContainer = styled.div`
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 480px;
`;

export default PiggyBank;
