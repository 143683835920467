import { ethers } from 'ethers';
import { getEnvironmentForDomain } from './environment';
import { PiggyBankBomb, PiggyBankBomb__factory } from './types/gen';

export const readOnlyContractForDomain = (domain: string): PiggyBankBomb => {
  const env = getEnvironmentForDomain(domain);
  const provider = new ethers.providers.StaticJsonRpcProvider(
    env.readOnlyProviderUrl
  );
  return PiggyBankBomb__factory.connect(env.contractAddress, provider);
};

export const readOnlyContract = (): PiggyBankBomb => {
  return readOnlyContractForDomain(window.location.hostname);
};
