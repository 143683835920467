import { BigNumber } from '@ethersproject/bignumber';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import { readOnlyContract } from '../contract';

type BaseEvent = {
  blockHash: string;
};

type DepositEvent = BaseEvent & {
  type: 'Deposit';
  from: string;
  value: BigNumber;
};

type GameStartedEvent = BaseEvent & {
  type: 'GameStarted';
  from: string;
  balance: BigNumber;
};

type WinningsStoredEvent = BaseEvent & {
  type: 'WinningsStored';
  winnerAddress: string;
  value: BigNumber;
};

type WinningsWithdrawnEvent = BaseEvent & {
  type: 'WinningsWithdrawn';
  winnerAddress: string;
  value: BigNumber;
};

export type Event =
  | DepositEvent
  | GameStartedEvent
  | WinningsStoredEvent
  | WinningsWithdrawnEvent;

type EventsHook = {
  eventLog: Event[];
  loading: boolean;
};

export type EventListener = (event: Event) => void;

const eventExistsInLog = (eventLog: Event[], event: Event) => {
  console.log('eventExistsInLog', eventLog, event);
  return eventLog.some((_) => _.blockHash === event.blockHash);
};

export const useEvents = (onNewEvent: EventListener): EventsHook => {
  const [eventLog, setEventLog] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;

    const roContract = readOnlyContract();
    const contract = new ethers.Contract(
      roContract.address,
      roContract.interface,
      roContract.provider
    );

    const eventFilter = {
      address: contract.address,
    };

    // @ts-ignore
    contract.queryFilter(eventFilter).then((events) => {
      // @ts-ignore
      const parsedEvents: Event[] = events.reverse().map((e) => {
        switch (e.event) {
          case 'Deposit':
            return {
              blockHash: e.blockHash,
              type: 'Deposit',
              // @ts-ignore
              from: e.args[0],
              // @ts-ignore
              value: e.args[1],
            };
          case 'GameStarted':
            return {
              blockHash: e.blockHash,
              type: 'GameStarted',
              // @ts-ignore
              from: e.args[0],
              // @ts-ignore
              balance: e.args[1],
            };
          case 'WinningsStored':
            return {
              blockHash: e.blockHash,
              type: 'WinningsStored',
              // @ts-ignore
              winnerAddress: e.args[0],
              // @ts-ignore
              value: e.args[1],
            };
          case 'WinningsWithdrawn':
            return {
              blockHash: e.blockHash,
              type: 'WinningsWithdrawn',
              // @ts-ignore
              winnerAddress: e.args[0],
              // @ts-ignore
              value: e.args[1],
            };
          default:
            throw new Error(`Unexpected event: ${e.event}`);
        }
      });

      console.log(parsedEvents);

      console.log('events', events);
      if (!didCancel) {
        setEventLog((prev) => [...parsedEvents, ...prev]);
        setLoading(false);
      }
    });

    return () => {
      didCancel = true;
    };
  }, [onNewEvent]);

  useEffect(() => {
    if (loading) {
      return;
    }

    let didCancel = false;

    const roContract = readOnlyContract();
    const depositListener = (from: string, value: BigNumber, rawEvent: any) => {
      const event: Event = {
        blockHash: rawEvent.blockHash,
        type: 'Deposit',
        from,
        value,
      };

      if (didCancel) {
        return;
      }

      setEventLog((prev) => {
        if (eventExistsInLog(prev, event)) {
          return prev;
        }

        return [event, ...prev];
      });
      onNewEvent(event);
    };
    roContract.on(roContract.filters.Deposit(), depositListener);

    const gameStartedListener = (
      from: string,
      balance: BigNumber,
      rawEvent: any
    ) => {
      const event: Event = {
        blockHash: rawEvent.blockHash,
        type: 'GameStarted',
        from,
        balance,
      };

      if (didCancel) {
        return;
      }

      setEventLog((prev) => {
        if (eventExistsInLog(prev, event)) {
          return prev;
        }

        return [event, ...prev];
      });
      onNewEvent(event);
    };
    roContract.on(roContract.filters.GameStarted(), gameStartedListener);

    const winningsStoredListener = (
      winnerAddress: string,
      value: BigNumber,
      rawEvent: any
    ) => {
      const event: Event = {
        blockHash: rawEvent.blockHash,
        type: 'WinningsStored',
        winnerAddress,
        value,
      };

      if (didCancel) {
        return;
      }

      setEventLog((prev) => {
        if (eventExistsInLog(prev, event)) {
          return prev;
        }

        return [event, ...prev];
      });
      onNewEvent(event);
    };
    roContract.on(roContract.filters.WinningsStored(), winningsStoredListener);

    const winningsWithdrawnListener = (
      winnerAddress: string,
      value: BigNumber,
      rawEvent: any
    ) => {
      const event: Event = {
        blockHash: rawEvent.blockHash,
        type: 'WinningsWithdrawn',
        winnerAddress,
        value,
      };

      if (didCancel) {
        return;
      }

      setEventLog((prev) => {
        if (eventExistsInLog(prev, event)) {
          return prev;
        }

        return [event, ...prev];
      });
      onNewEvent(event);
    };
    roContract.on(
      roContract.filters.WinningsWithdrawn(),
      winningsWithdrawnListener
    );

    return () => {
      didCancel = true;

      roContract.removeListener(roContract.filters.Deposit(), depositListener);
      roContract.removeListener(
        roContract.filters.GameStarted(),
        gameStartedListener
      );
      roContract.removeListener(
        roContract.filters.WinningsStored(),
        winningsStoredListener
      );
      roContract.removeListener(
        roContract.filters.WinningsWithdrawn(),
        winningsWithdrawnListener
      );
    };
  }, [onNewEvent, loading]);

  return {
    eventLog,
    loading,
  };
};
