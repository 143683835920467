import makeBlockie from 'ethereum-blockies-base64';
import { formatEther } from 'ethers/lib/utils';
import { getEnvironment } from '../environment';
import { useEvents, EventListener, Event } from '../hooks/useEvents';
import { Heading } from './Heading';
import { Container } from './Container';

const onNewEvent: EventListener = (event) => {
  console.log('New Event', event);
};

type EventItemProps = {
  event: Event;
};

const EventItem = ({ event }: EventItemProps) => {
  let message = '';
  let address = '';

  if (event.type === 'Deposit') {
    message = `Deposit of ${formatEther(
      event.value
    )} ETH. Clock reset to 60 mins!`;
    address = event.from;
  } else if (event.type === 'GameStarted') {
    message = 'New Game Started!';
    address = event.from;
  } else if (event.type === 'WinningsWithdrawn') {
    message = 'Winnings were withdrawn';
    address = event.winnerAddress;
  } else if (event.type === 'WinningsStored') {
    message = 'The PiggyBank winnings have been won!';
    address = event.winnerAddress;
  }

  return (
    <div style={{ display: 'flex', padding: '10px' }}>
      <img
        src={makeBlockie(address)}
        style={{
          width: '7%',
          height: '7%',
          borderRadius: '5px',
          marginRight: '15px',
        }}
        alt="Pixelated avatar generated from ethereum address"
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {message}
        <small>
          By:{' '}
          <a
            href={`https://${
              getEnvironment().etherscanDomain
            }/address/${address}`}
            target="_blank"
            rel="noreferrer"
          >
            {address}
          </a>
        </small>
      </div>
    </div>
  );
};

const EventLog = () => {
  const { eventLog } = useEvents(onNewEvent);

  return (
    <>
      <Heading>Recent Events</Heading>
      <Container>
        {eventLog
          .reverse()
          .slice(0, 10)
          .map((event) => (
            <EventItem key={`event-${event.blockHash}`} event={event} />
          ))}

        {eventLog.length > 10 && (
          <p>
            <a
              href={`https://${getEnvironment().etherscanDomain}/address/${
                getEnvironment().contractAddress
              }#events`}
              target="_blank"
              rel="noreferrer"
            >
              View more...
            </a>
          </p>
        )}
      </Container>
    </>
  );
};



export default EventLog;
