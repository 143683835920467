import useInterval from '@use-it/interval';
import { formatEther } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { UnixTimestamp, Seconds, GameState } from '../types';
import { formatTime } from '../utils';
import styled from 'styled-components';

import PiggyBank from './piggybank/index';
import DepositCoin from './DepositCoin';

type Props = {
  gameState: GameState | null;
  refreshGameState: () => void;
};

const GameSection = ({ gameState, refreshGameState }: Props) => {
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const [isPiggyHappy, setIsPiggyHappy] = useState(true);

  useEffect(() => {
    if (!gameState) {
      return;
    }

    setSecondsRemaining(
      calculateSecondsRemaining(
        gameState.lastDepositTimestamp,
        gameState.maxBombTime
      )
    );
  }, [gameState]);

  useInterval(() => {
    if (!gameState) {
      return;
    }

    if (secondsRemaining > 0) {
      const updatedSecondsRemaining = calculateSecondsRemaining(
        gameState.lastDepositTimestamp,
        gameState.maxBombTime
      );

      // Deposit happened within last 5 minutes
      if (
        gameState.maxBombTime - updatedSecondsRemaining < 60 * 5 &&
        isPiggyHappy === false
      ) {
        setIsPiggyHappy(true);
      } else if (
        gameState.maxBombTime - updatedSecondsRemaining > 60 * 5 &&
        isPiggyHappy === true
      ) {
        setIsPiggyHappy(false);
      }

      setSecondsRemaining(updatedSecondsRemaining);
    } else if (gameState.state === 'IN_PROGRESS') {
      refreshGameState();
    }
  }, 1000);

  if (!gameState) {
    return (
      <>
        <StatusMessage>
          In <Highlighted>__:__</Highlighted> minutes{' '}
          <Highlighted>_____ ETH</Highlighted> will be given to: <br />
          __________________________________________
        </StatusMessage>
        <DepositCoin refreshGameState={refreshGameState} />
        <PiggyBank secondsRemaining={null} isHappy={isPiggyHappy} />
      </>
    );
  }

  const { piggyBankBalance, lastDepositAddress } = gameState;

  const timeUnits = secondsRemaining > 60 ? 'minutes' : 'seconds';

  // TODO: Need to round ETH value?
  // TODO: Should ETH value be only 95%?
  return (
    <>
      <StatusMessage>
        In <Highlighted>{formatTime(secondsRemaining)}</Highlighted> {timeUnits}{' '}
        <Highlighted>{formatEther(piggyBankBalance)} ETH</Highlighted> will be
        given to: <br />
        {lastDepositAddress}
      </StatusMessage>
      <PiggyBankCoinContainer>
        <DepositCoin refreshGameState={refreshGameState} />
        <PiggyBank secondsRemaining={secondsRemaining} isHappy={isPiggyHappy} />
      </PiggyBankCoinContainer>
    </>
  );
};

const calculateSecondsRemaining = (
  lastDepositTimestamp: UnixTimestamp,
  maxBombTime: Seconds
): Seconds => {
  return Math.max(
    lastDepositTimestamp + maxBombTime - Math.round(Date.now() / 1000),
    0
  );
};

const StatusMessage = styled.p`
  text-align: center;
  font-size: 22px;
`;

const Highlighted = styled.span`
  font-weight: bold;
`;

const PiggyBankCoinContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export default GameSection;
